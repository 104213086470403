<template>
    <div>
      <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2>Portfolios | Performers</h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li>Performers</li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-8">
              <article class="entry entry-single">
                <!-- Portfolio Search Box & Add Button-->
                <div>
                  <b-row>
                    <b-col sm="8"><b-form-input v-model="SearchTable" v-on:keyup="SearchProfiles()" placeholder="Search by name"></b-form-input></b-col>
                      <b-col sm="4" >
                      <p class="tag-rec-cnt" v-if="TotRows>50"> Showing {{PerPage}} of {{TotRows}}</p>
                      <p class="tag-rec-cnt" v-if="TotRows<50"> Showing {{TotRows}} records</p>                      
                    </b-col>                     
                    <!-- <b-col sm="4">                      
                      <div  class="thira-button" >
                        <router-link to="/share/user-portfolio">Submit Your Profile</router-link>
                      </div> 
                    </b-col>    -->
                  </b-row>   
                </div>
                <!-- Portfolio card Listing goes here -->
                <div id="ThiraCard">
                  <b-row class="row no-gutters">
                    <div class="col-md-4 mt-4" v-for="Data in SearchList" v-bind:key="Data.id">
                      <div class="card profile-card-5">
                        <div class="card-img-block">
                          <router-link :to="{name:'performers-detail', params:{ ProfileId:Data.seq_no, SlugName:Data.slug_name}}"><img class="card-img-top" v-bind:src="Data.profile_photo" alt="Image"></router-link>
                        </div>
                        <div class="card-body pt-0 ">
                          <!-- <h5 class="card-title">{{Data.profile_name}}</h5> -->
                          <h5 class="page-title-text text-truncate" style="width: 12rem;"><router-link :to="{name:'performers-detail', params:{ ProfileId:Data.seq_no, SlugName:Data.slug_name}}"><h5>{{Data.profile_name}}</h5></router-link></h5>
                          <p class="card-text font-italic text-truncate" style="width: 13rem;">{{Data.profile_title}} <br></p>
                          <p class="card-text font-italic text-truncate" style="width: 13rem;">{{Data.prof_city}} \ {{Data.prof_state}} \ {{Data.prof_country}}</p>
                          <div>
                            <!-- <a v-if='Data.profile_facebook !== ("" || null)' v-bind:href="'http://'+Data.profile_facebook" target="_blank"><i class="bx bxl-facebook"></i></a>
                            <a v-if='Data.profile_youtube !== ("" || null)' v-bind:href="'http://'+Data.profile_youtube" target="_blank"><i class="bx bxl-twitter"></i></a>
                            <a v-if='Data.profile_instagram !== ("" || null)' v-bind:href="'http://'+Data.profile_instagram" target="_blank"><i class="bx bxl-instagram"></i></a>
                            <a v-if='Data.profile_twitter !== ("" || null)' v-bind:href="'http://'+Data.profile_twitter" target="_blank"><i class="bx bxl-instagram"></i></a>
                            <a v-if='Data.profile_website !== ("" || null)' v-bind:href="'http://'+Data.profile_website" target="_blank"><i class="bx bxl-instagram"></i></a> -->
                          </div>
                        </div>
                      </div>
                    </div>             
                  </b-row>    
                </div>  
              <b-pagination pills class="mt-5" v-show="TotRows>50"
                v-model="CurrPage"
                :total-rows="TotRows"
                :per-page="PerPage"
                aria-controls="ThiraCard"
                @change="handlePageChange"
                align="center"
                size="md"
              ></b-pagination>   

              </article>
            </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar">
                <SideCatgPerformers/>
                <SideRecentStageShows/>
                <SideRecentAcademies/>
                <!-- sidebar Filter By --> 
                <!-- <h3 class="sidebar-title">Search Artists By</h3>
                <div class="sidebar-item tags">
                  <ul>
                    <li><router-link :to="{name:'performers',params:{ Maincatg:'Mohiniyattam'}}">Mohiniyattam</router-link></li>
                    <li><router-link :to="{name:'performers',params:{ Maincatg:'Bharatha Natyam'}}">Bharatha Natyam</router-link></li>
                    <li><router-link :to="{name:'performers',params:{ Maincatg:'Kathakali'}}">Kathakali</router-link></li>
                    <li><router-link :to="{name:'performers',params:{ Maincatg:'Kudiyattam'}}">Kudiyattam</router-link></li>
                    <li><router-link :to="{name:'performers',params:{ Maincatg:'Kuchipudi'}}">Kuchipudi</router-link></li>
                    <li><router-link :to="{name:'performers',params:{ Maincatg:'Dance'}}">Dance</router-link></li>
                    <li><router-link :to="{name:'performers',params:{ Maincatg:'Music'}}">Music</router-link></li>
                    <li><router-link :to="{name:'performers',params:{ Maincatg:'Theyyam'}}">Theyyam</router-link></li>
                    <li><router-link :to="{name:'performers',params:{ Maincatg:'Classical Music'}}">Classical Music</router-link></li>
                    <li><router-link :to="{name:'performers',params:{ Maincatg:'Kathak'}}">Kathak</router-link></li>
                    <li><router-link :to="{name:'performers',params:{ Maincatg:'Ritual Arts'}}">Ritual Arts</router-link></li>
                    <li><router-link :to="{name:'performers',params:{ Maincatg:'Instruments'}}">Instruments</router-link></li>
                    <li><router-link :to="{name:'performers',params:{ Maincatg:'Drama'}}">Drama</router-link></li>
                    <li><router-link :to="{name:'performers',params:{ Maincatg:'Magic'}}">Magic</router-link></li>
                  </ul>
                </div> -->
                <!-- sidebar recent posts-->
                <SideRecentTroupes/>
              </div>

            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)

export default {
  name:"PerformingArtist",
  metaInfo: {
    title: "Thiraseela :: ",
    titleTemplate: "%s Performing Artist Portfolios | Book Stage Shows | Online Program Booking"
  },
  data () {
    return {
      SearchBy: "",
      SearchTable: '',
      RespAllProfiles: [],
      NoMoreData: false,
        // Pagination Variables
        CurrPage: 1,
        MaxPage: 0,
        TotRows: 0,
        PerPage: 75,
    }
  },
    watch: {
       '$route' (to)
       {
        if(this.$route.params.Maincatg == 'All') {
          this.ReadAllProfiles(to.params.Maincatg)
        }
        else {
          this.ReadProfilesByCatg(to.params.Maincatg)
        }                 
       }
    },    
  mounted()
    {
      this.GetTotRecCnt()      
      if(this.$route.params.Maincatg == 'All') {
        this.ReadAllProfiles()        
        // this.scroll()
      } 
      else {
        this.ReadProfilesByCatg(this.$route.params.Maincatg)
      }           
    },
  methods: {

    // scroll() {
    //   window.onscroll = () => {
    //     var ContArea = document.getElementById('ListArea').offsetHeight;
    //     console.log('ContArea',ContArea)        
    //     let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === (document.documentElement.offsetHeight);
    //     console.log('innerHeight',document.documentElement.scrollTop + window.innerHeight )
    //     console.log('offsetHeight',(document.documentElement.offsetHeight))
    //     var ContArea = document.getElementById('ListArea').offsetHeight;
    //     console.log('ContArea',ContArea)        
    //     let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight == (ContArea);
    //     console.log('innerHeight',document.documentElement.scrollTop + window.innerHeight )
    //     console.log('offsetHeight',(document.documentElement.offsetHeight))        

        
    //     if (bottomOfWindow) {
    //       this.PageNo=this.PageNo + 1;
    //       console.log('PageNo=',this.PageNo)
    //       if ( ! this.NoMoreData )
    //         {
    //           this.ReadAllProfiles()
    //         }
    //       else 
    //         {
    //           console.log('No more records!')
    //         }
    //     }
    //   }
    // },
      handlePageChange(value) {
      this.CurrPage = value;
      document.body.scrollIntoView();
      // console.log('this.CurrPage',this.CurrPage)
      // if (this.CurrPage > this.MaxPage) 
      //   {
          this.ReadAllProfiles();
        //   this.MaxPage = this.CurrPage
        // }
      // console.log('this.MaxPage',this.MaxPage)
      },

      GetTotRecCnt(){
        const REQformData = new FormData();
        REQformData.append('table_name', 'users_profile_main');          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=GetTotRecCnt',REQformData)
        .then((resp)=>{
        const FirstObject = resp.data.GetTotRecCnt[0];
        this.TotRows = FirstObject.Tot_Rec_Cnt;
        // console.log('TotRows',this.TotRows)
      })
      },       

      // ReadAllProfiles(){
      //   const REQformData1 = new FormData();
      //   REQformData1.append('Page_No', this.CurrPage);          
      //   Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllProfiles', REQformData1)
      //   .then((resp)=>{
      //     resp.data.AllProfiles.forEach(element => this.RespAllProfiles.push(element))
      //   })
      // },

      ReadAllProfiles(){
        const REQformData1 = new FormData();
        REQformData1.append('Page_No', this.CurrPage);
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllProfiles', REQformData1)
        .then((resp)=>{
          // resp.data.AllProfiles.forEach(element => this.RespAllProfiles.push(element))
            this.RespAllProfiles=resp.data.AllProfiles;

        })
        // .catch(this.NoMoreData=true)
      },

      SearchProfiles(){
        // console.log('Length',this.SearchTable.length)
        if (this.SearchTable.length > 3) 
          { 
            // console.log('Executing','Calling API')
          const SearchformData = new FormData();
          SearchformData.append('SearchString', this.SearchTable);        
          Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=SearchProfiles', SearchformData)
          .then((resp)=>{
            this.RespAllProfiles=resp.data.SearchProfiles;
          })
          }
         if (this.RespAllProfiles.length < 75)
         {
           this.ReadAllProfiles()
         }
        //  else
        //    {
        //      this.ReadAllProfiles()
        //    }
      },      

      ReadProfilesByCatg(Maincatg){     
        const REQformData2 = new FormData();
        REQformData2.append('catg1', Maincatg);               
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadProfilesByCatg', REQformData2)
        .then((resp)=>{
        this.RespAllProfiles=resp.data.AllProfiles;
      })
      },        
  },   
    computed: {
    SearchList: function(){
        return this.RespAllProfiles.filter((RespAllProfiles) => {
        return RespAllProfiles.profile_name.toLowerCase().match(this.SearchTable.toLowerCase())
      });
    },
      // SearchList: function()
      //   {
      //     return this.RespSearchList.slice(
      //     (this.CurrPage - 1) * this.PerPage,
      //     this.CurrPage * this.PerPage,
      //     );
      //   }         
  }
}
</script>

<style scoped>
.profile-card-5{
    margin-top:20px;
    /* height: 450px; */
    height: 340px;
    /* overflow: hidden;     */
}
.profile-card-5 .btn{
    border-radius:2px;
    text-transform:uppercase;
    font-size:12px;
    padding:7px 20px;
}
.profile-card-5 .card-img-block {
    width: 91%;
    margin: 0 auto;
    position: relative;
    top: -20px;
}
.profile-card-5 .card-img-block img{
    border-radius:10px;
    box-shadow:0 0 10px rgba(0,0,0,0.63);
    height: 260px;
    overflow: hidden;
}
.profile-card-5 h5{
    color:#e75480;
    font-weight:800;
    font-size:16px;
}
.profile-card-5 p{
    margin-top: -14px;
    font-size:14px;
    font-weight:300;
}
.profile-card-5 .btn-primary{
    background-color:#4E5E30;
    border-color:#4E5E30;
}
.profile-card-5 .icon-block{
    float:left;
    width:100%;
}
.profile-card-5 .icon-block a{
    text-decoration:none;
}
.profile-card-5 i {
  display: inline-block;
    font-size: 19px;
    color: #ffffff;
    background-color:#e75480;
    text-align: center;
    border: 1px solid #1e4356;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    margin:0 5px;
}
.profile-card-5 i:hover {
  background-color:#e75480;
  color:#fff;
}
.button-style {
  background-color:#e75480;
  color:#fff;
  margin:0 5px;
  text-align: center;
}
a:hover {
    color: rgb(165, 19, 19);
    text-decoration: none;
}
.pre-formatted {
  white-space: pre-line;
  text-align: justify;
  max-height: 450px;
  overflow: auto;
  display: inline-block;
}
  .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
  }
</style>
